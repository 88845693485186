const key = 'authToken'

export const storeToken = (authToken) => {
  localStorage.setItem(key, authToken)
}

export const getToken = () => {
  const token = localStorage.getItem(key)
  return token
}

export const getUserToken = () => {
  return getToken()
}

export const removeToken = () => {
  localStorage.removeItem(key)
}
