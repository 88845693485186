import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
//  start of my added code
import { me } from './api/auth.api'
import useApi from './hooks/useApi'
import { useDispatch } from 'react-redux'
import { setUser } from './redux/auth.slice'
import { getToken, removeToken } from './auth/storage'
import useAuth from './auth/useAuth'
//  end of my added code

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse">loading</div>
  </div>
)
const Loading = () => {
  return loading
}

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/beforeLogin/Login'))
const Register = React.lazy(() => import('./views/pages/beforeLogin/Register'))
const Page404 = React.lazy(() => import('./views/pages/beforeLogin/Page404'))
const Page500 = React.lazy(() => import('./views/pages/beforeLogin/Page500'))

const App = () => {
  //  start my added code
  const [appIsReady, setAppIsReady] = useState(false)
  const dispatch = useDispatch()
  const meApi = useApi(me)
  const { user } = useAuth()
  const restoreUser = async () => {
    const userToken = getToken()
    if (userToken) {
      //  get my profile from the server
      let response = await meApi.request()
      if (!response.ok) {
        if ((response.status = 403)) removeToken()
        // Handle other error
        // console.log('Error occurred')
        //setError('خطأ داخلي,الرجاء المحاولة لاحقا.');
        return
      }
      //  Handle successful response
      dispatch(setUser(response.data.user))
    }
  }

  async function prepare() {
    try {
      await restoreUser()
      setAppIsReady(true)
    } catch (e) {
      console.warn(e)
    }
  }
  useEffect(() => {
    prepare()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (!appIsReady) {
    return <Loading />
  }
  //  end of my added code
  return (
    <BrowserRouter basename="/">
      <Suspense fallback={loading}>
        <Routes>
          {!user ? (
            <>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route path="*" name="Home" element={<Login />} />
            </>
          ) : (
            <>
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </>
          )}
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
