import { useState } from 'react'

const useApi = (apiFunc) => {
  const [data, setData] = useState([])
  const [error, setError] = useState(false)
  const [done, setDone] = useState(false)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')

  const request = async (...args) => {
    setLoading(true)
    setDone(false)
    const response = await apiFunc(...args)
    setLoading(false)
    setDone(true)
    setError(!response.ok)
    setMessage(response.data?.message || 'xxx')
    setData(response.data)
    setStatus(response.status)
    return response
  }

  return { data, error, message, status, loading, done, request }
}
export default useApi
