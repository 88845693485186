import { create } from 'apisauce'
import { getToken } from '../auth/storage'
import settings from '../config/settings'

const apiClient = create({
  baseURL: settings.baseURL + '/api',
})

apiClient.addRequestTransform((request) => {
  const authToken = getToken()
  if (!authToken) {
    return
  }
  request.headers['x-auth-token'] = authToken
})

export default apiClient
