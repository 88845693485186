import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
  },
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    setUser: (state, action) => {
      state.user = action.payload
    },

    setAvatar: (state, action) => {
      state.user.avatar = action.payload
    },

    setAccountValid: (state, action) => {
      state.user.valid = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUser, setAvatar, setAccountValid } = authSlice.actions

export default authSlice.reducer
