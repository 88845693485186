import client from './client'

// const registerPatient = userInfo => client.post('/auth/registerPatient', userInfo);
// const validateOTPPhone = otp => client.post('/auth/validateOTPPhone', {otp});
// const validateOTPPasswordReset = (otp, phone) => client.post('/auth/validateOTPPasswordReset', {otp, phone});
// const resendOTPPhone = () => client.post('/auth/resendOTPPhone', {});
// const requestPasswordReset = phone => client.post('/auth/requestPasswordReset', {phone});
// const resetPassword = (password, phone, otp) => client.post('/auth/resetPassword', {password, phone, otp});
export const login = (phoneIndicator, phone, password) => {
  return client.post('/auth/login', { phoneIndicator, phone, password })
}
export const registerAdministrator = (userInfo) => {
  return client.post('/auth/registerAdministrator', userInfo)
}
export const me = () => {
  return client.get('/auth/me')
}
