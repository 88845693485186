import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    sidebarShow: true,
    appVersion: null,
    tabNavigation: null,
  },
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    setAppVersion: (state, action) => {
      state.appVersion = action.payload
    },
    setSidebarView: (state, action) => {
      return { ...state, sidebarShow: action.payload }
    },
  },
})
//dispatch(setAppVersion(response.data.appVersion));
export const { setAppVersion, setSidebarView } = appSlice.actions

export default appSlice.reducer
