import { useDispatch, useSelector } from 'react-redux'

import { storeToken, removeToken } from './storage'
import { setUser, setAvatar } from '../redux/auth.slice'

const useAuth = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.authState.user)

  const storeUserToken = (authToken) => {
    storeToken(authToken)
  }

  const logOut = () => {
    dispatch(setUser(null))
    removeToken()
  }

  const updateAvatar = (image) => {
    dispatch(setAvatar(image))
  }

  return { user, storeUserToken, logOut, updateAvatar }
}
export default useAuth
